import React, { useCallback, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { themeCssVar as globalTheme, UiEngineProvider } from '@tinderapp/ui-react';

// NOTE: Stylesheet imports are injected into ALL PAGES regardless of whether the layout uses them.
import '@tinderapp/ui-core/fonts/face.css';
import '@tinderapp/ui-core/themes/default.css';

import { HEADER_HEIGHT_SMALL, HEADER_HEIGHT_LARGE, SPACING_VERTICAL } from '../lib/sizing';
import {
  centerContainerMaxWidth,
  minWidthSmall,
  maxWidthSmall,
  minWidthMedium,
} from '../lib/styles';
import { useMaxWidthSmall } from '../lib/hooks';

import GlobalStyle from './GlobalStyle';
import Header from './Header';
import HeaderNav from './HeaderNav';
import Main from './Main';
import Footer from './Footer';

const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: ${HEADER_HEIGHT_SMALL}px;
  background-color: ${globalTheme.color.backgroundPrimary};
  border-bottom: 1px solid ${globalTheme.color.borderSecondary};
  z-index: 100;

  ${Header} {
    height: 100%;
    ${centerContainerMaxWidth}
  }

  ${HeaderNav} {
    position: fixed;
    top: ${HEADER_HEIGHT_SMALL}px;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;

    background-color: ${globalTheme.color.backgroundPrimary};

    transition: transform 250ms ease-out, visibility 250ms linear;

    &:not(.active) {
      transition-delay: 0, 200ms;
      transform: translateX(100%);
      visibility: hidden;
      pointer-events: none;
    }
  }

  @media ${minWidthSmall} {
    ${HeaderNav} {
      display: none;
    }
  }

  @media ${minWidthMedium} {
    height: ${HEADER_HEIGHT_LARGE}px;

    ${HeaderNav} {
      top: ${HEADER_HEIGHT_LARGE}px;
    }
  }
`;

const MainWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  z-index: 80;

  ${Main} {
    ${centerContainerMaxWidth}
  }
`;

const FooterWrapper = styled.div`
  position: relative;
  background-color: ${globalTheme.color.backgroundPrimary};
  border-top: 1px solid ${globalTheme.color.borderSecondary};
  z-index: 90;
  padding-bottom: ${SPACING_VERTICAL(4)};

  @media ${maxWidthSmall} {
    display: none;
  }

  ${Footer} {
    ${centerContainerMaxWidth}
  }
`;

const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Layout = ({ children, locale }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuClick = useCallback(() => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  }, []);

  const isSmallWindow = useMaxWidthSmall();

  useLayoutEffect(() => {
    if (!isSmallWindow) {
      setMenuOpen(false);
    }
  }, [isSmallWindow]);

  useLayoutEffect(() => {
    document.body.classList.toggle('locked', isSmallWindow && menuOpen);
    return () => {
      document.body.classList.remove('locked');
    };
  }, [isSmallWindow, menuOpen]);

  return (
    <UiEngineProvider>
      <GlobalStyle locale={locale} />
      <LayoutWrapper>
        <HeaderWrapper>
          <Header menuOpen={menuOpen} onMenuClick={isSmallWindow ? handleMenuClick : undefined} />
          <HeaderNav className={menuOpen ? 'active' : 'inactive'} />
        </HeaderWrapper>
        <MainWrapper>
          <Main>{children}</Main>
        </MainWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </LayoutWrapper>
    </UiEngineProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string,
  messages: PropTypes.shape({}),
  supportedLanguages: PropTypes.shape({}),
};

Layout.defaultProps = {
  locale: undefined,
  messages: undefined,
  supportedLanguages: {},
};

export default Layout;
