import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DEFAULT_LOCALE, getLocale, getMessages } from '../data/messages';

import { LocalizationProvider } from '../contexts/LocalizationContext';
import Layout from '../components/Layout';
import SEO from '../components/Seo';
import TextContent from '../components/TextContent';

const NotFoundPage = ({ locale = DEFAULT_LOCALE, redirectMap = {}, supportedLanguages = {} }) => (
  <LocalizationProvider
    locale={getLocale(locale)}
    messages={getMessages(locale)}
    localizedPaths={supportedLanguages}
    redirectMap={redirectMap}
  >
    <Layout locale={getLocale(locale)} messages={getMessages(locale)}>
      <SEO title="Not Found" lang={locale} />
      <TextContent>
        <h1>
          <FormattedMessage id="404.title" />
        </h1>
        <p>
          <FormattedMessage id="404.body" />
        </p>
      </TextContent>
    </Layout>
  </LocalizationProvider>
);

export default NotFoundPage;
